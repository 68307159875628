<template>
    <span>
        <b-button variant="primary" size="sm" @click.prevent="toggleModal()"><i class="fas fa-id-card"></i>
            Calcola</b-button>

        <div :id="modalid" v-if="show" class="border border-primary rounded taxcodetop">
            <h3 class="mb-4">Calcolo Codice Fiscale</h3>
            <form class="form">
                <div class="form-group row">
                    <label for="surname" class="col-md-3">Cognome *</label>
                    <div class="col-md-9">
                        <input id="surname" v-model="surname" type="text" maxlength="80"
                            class="form-control form-control-solid border border-primary">
                    </div>
                </div>

                <div class="form-group row">
                    <label for="name" class="col-md-3">Nome *</label>
                    <div class="col-md-9">
                        <input id="name" v-model="name" type="text" maxlength="80"
                            class="form-control form-control-solid border border-primary">
                    </div>
                </div>

                <div class="form-group row">
                    <label for="sex" class="col-md-3">Sesso *</label>
                    <div class="col-md-9">
                        <b-form-radio-group id="sex" v-model="sex"
                            :options="[{ text: 'Uomo', value: 'M' }, { text: 'Donna', value: 'F' }]"
                            :state="!(typeof errors['sex'] !== 'undefined')">
                            <b-form-invalid-feedback :state="typeof errors['sex'] !== 'undefined'"
                                :style="(typeof errors['sex'] !== 'undefined' ? 'display: block;' : '')">
                                <div v-for="error in errors['sex']">{{ error }}</div>
                            </b-form-invalid-feedback>
                        </b-form-radio-group>
                    </div>
                </div>

                <div class="form-group row">
                    <label for="date" class="col-md-3">Data di nascita *</label>
                    <div class="col-md-9">
                        <date-picker v-model="date" type="date" placeholder="Seleziona una data" format="DD/MM/YYYY"
                            time-title-format="DD/MM/YYYY" value-type="YYYY-MM-DD"
                            :input-class="(typeof errors[date] !== 'undefined' ? 'mx-input is-invalid' : 'mx-input')"
                            :shortcuts="date_shortcuts"></date-picker>
                    </div>
                </div>

                <div class="form-group row">
                    <label for="date" class="col-md-3">Località *</label>

                    <multiselect style="width: 100%;" id="idcity" v-model="idcity" :options="cities" :multiple="false"
                        :close-on-select="true" :clear-on-select="false" placeholder="Cerca una località"
                        label="cityname" track-by="id" :allow-empty="false" selectLabel="" selectedLabel=""
                        deselectLabel="" showLabels=""
                        :class="{ 'is-invalid': typeof errors['idcity'] !== 'undefined' }" :searchable="true"
                        :loading="isLoading" :internal-search="false" :options-limit="300" :limit="10"
                        @search-change="asyncFind">
                        <template v-slot:noOptions>
                            Cerca una località
                        </template>
                        <template slot="noResult">
                            Nessuna località trovata. Si prega di cambiare i termini della ricerca.
                        </template>
                    </multiselect>
                </div>

                <div class="form-group row">
                    <label for="taxcode" class="col-md-3">Codice Fiscale *</label>
                    <div class="col-md-9">
                        <input id="taxcode" v-model="taxcode" type="text" maxlength="16"
                            class="form-control form-control-solid border border-primary">
                    </div>
                </div>
            </form>

            <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-danger mr-2" @click.prevent="closeModal()">Chiudi</button>
                <button type="button" class="btn btn-success mr-2" @click.prevent="calcolaCodiceFiscale()">Calcola e
                    Salva</button>
            </div>
        </div>
    </span>
</template>

<script>
import ApiService from "@/core/services/api.service";
import KTUtil from "@/assets/js/components/util";

export default {
    name: 'TaxCodeBtn',

    props: ['modalid'],

    data() {
        return {
            show: false,

            isLoading: false,
            cities: [],

            name: '',
            surname: '',
            sex: '',
            date: '',
            idcity: '',
            taxcode: '',

            errors: [],

            date_shortcuts: [
                {
                    text: 'Oggi',
                    onClick() {
                        const date = new Date();

                        const item = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);

                        return item;
                    },
                },
                {
                    text: 'Ieri',
                    onClick() {
                        const date = new Date();

                        const item = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);

                        item.setTime(item.getTime() - 3600 * 1000 * 24);

                        return item;
                    },
                },
                {
                    text: 'Ultima settimana',
                    onClick() {
                        const date = new Date();

                        const item = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);

                        item.setTime(item.getTime() - 3600 * 1000 * 24 * 7);

                        return item;
                    },
                },
                {
                    text: 'Ultimo mese',
                    onClick() {
                        const date = new Date();

                        const item = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);

                        item.setTime(item.getTime() - 3600 * 1000 * 24 * 30);

                        return item;
                    },
                },
                {
                    text: 'Ultimi 3 mesi',
                    onClick() {
                        const date = new Date();

                        const item = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);

                        item.setTime(item.getTime() - 3600 * 1000 * 24 * 90);

                        return item;
                    },
                },
                {
                    text: 'Ultimi 6 mesi',
                    onClick() {
                        const date = new Date();

                        const item = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);

                        item.setTime(item.getTime() - 3600 * 1000 * 24 * 180);

                        return item;
                    },
                },
                {
                    text: 'Ultimo anno',
                    onClick() {
                        const date = new Date();

                        const item = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);

                        item.setTime(item.getTime() - 3600 * 1000 * 24 * 365);

                        return item;
                    },
                },
            ],

            cf: {
                lettere: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],

                vocali: ['A', 'E', 'I', 'O', 'U'],

                mesi: {
                    '01': 'A',
                    '02': 'B',
                    '03': 'C',
                    '04': 'D',
                    '05': 'E',
                    '06': 'H',
                    '07': 'L',
                    '08': 'M',
                    '09': 'P',
                    '10': 'R',
                    '11': 'S',
                    '12': 'T',
                },

                conversione: {
                    '0': 1,
                    '1': 0,
                    '2': 5,
                    '3': 7,
                    '4': 9,
                    '5': 13,
                    '6': 15,
                    '7': 17,
                    '8': 19,
                    '9': 21,
                    '10': 2,
                    '11': 4,
                    '12': 18,
                    '13': 20,
                    '14': 11,
                    '15': 3,
                    '16': 6,
                    '17': 8,
                    '18': 12,
                    '19': 14,
                    '20': 16,
                    '21': 10,
                    '22': 22,
                    '23': 25,
                    '24': 24,
                    '25': 23,
                },

                pattern_vocali: /[^AEIOU ]/,
            },
        };
    },

    created() {
    },

    mounted() {
    },

    watch: {
    },

    methods: {
        toggleModal() {
            if (this.show) {
                this.closeModal();
            } else {
                this.showModal();
            }
        },

        showModal() {
            this.show = true;

            let vm = this;
            setTimeout(() => {
                let viewPort = KTUtil.getViewPort();
                let windowHeight = viewPort.height;
                let divHeight = KTUtil.outerHeight(document.getElementById(vm.modalid), false);
                let top = parseInt(((windowHeight - divHeight) / 2));

                var duration = 0;
                var targetPos = KTUtil.offset(document.getElementById(vm.modalid)).top;
                var scrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
                var from, to;

                from = scrollPos;
                to = targetPos - 100;

                KTUtil.animate(from, to, 0, function (value) {
                    document.documentElement.scrollTop = value;
                    document.body.parentNode.scrollTop = value;
                    document.body.scrollTop = value;
                }); //, easing, done
            }, 100);

            //this.$bvModal.show(this.modalid);
        },

        closeModal() {
            this.show = false;

            var duration = 0;
            var targetPos = KTUtil.offset(this.$el).top;
            var scrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
            var from, to;

            from = scrollPos;
            to = targetPos - 150;

            KTUtil.animate(from, to, 0, function (value) {
                document.documentElement.scrollTop = value;
                document.body.parentNode.scrollTop = value;
                document.body.scrollTop = value;
            }); //, easing, done
            //this.$bvModal.hide(this.modalid);
        },

        asyncFind(query) {
            this.isLoading = true;

            let url = '/sportingclub/get-cities?query=' + encodeURIComponent(query);
            ApiService.query(url)
                .then((response) => {
                    this.cities = response.data;
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);

                    this.cities = [];
                    this.isLoading = false;
                });

        },

        estraiConsonanti(parola) {
            let result = '';

            parola = parola.toUpperCase();

            for (let i = 0; i < parola.length; i++) {
                if (parola.charAt(i).match(this.cf.pattern_vocali)) result += parola.charAt(i);
            }

            return result;
        },

        estraiVocali(parola) {
            let result = '';

            parola = parola.toUpperCase();

            for (let i = 0; i < parola.length; i++) {
                if (parola.charAt(i).match(/[AEIOU]/)) result += parola.charAt(i);
            }

            return result;
        },

        controllaNome(nome) {
            let parola = this.estraiConsonanti(nome);

            if (parola.length > 3) {
                parola = parola.charAt(0) + parola.charAt(2) + parola.charAt(3);
            }

            if (parola.length < 3) {
                let vocali_nome = this.estraiVocali(nome);

                let v = 0;
                while (parola.length < 3 && vocali_nome.length >= (v + 1)) {
                    parola += vocali_nome.charAt(v);
                    v++;
                }
            }

            return parola;
        },

        controllaCognome(cognome) {
            let parola = this.estraiConsonanti(cognome);

            if (parola.length > 3) return parola.charAt(0) + parola.charAt(1) + parola.charAt(2);

            if (parola.length < 3) {
                let vocali_cognome = this.estraiVocali(cognome);

                let v = 0;
                while (parola.length < 3 && vocali_cognome.length >= (v + 1)) {
                    parola += vocali_cognome.charAt(v);
                    v++;
                }
            }

            return parola;
        },

        controllaNascita(data, sesso) {
            let ritorno = '';

            let com = data.split('-');

            if (sesso === 'F') {
                com[2] *= 1;
                com[2] += 40;
            }

            let giorno = com[2];

            let mese = this.cf.mesi[com[1]];

            let anno = com[0].charAt(2) + com[0].charAt(3);

            ritorno = anno + mese + giorno;

            return ritorno;
        },

        generaControllo(cfp) {
            let ritorno = '';
            let sommatoria = 0;

            for (let i = 0; i < cfp.length; i++) {
                if (i % 2 != 0) {
                    if (isNaN(cfp.charAt(i))) {
                        let trovato = 0;

                        for (let j = 0; j < this.cf.lettere.length && trovato == 0; j++) {
                            if (this.cf.lettere[j] == cfp.charAt(i)) {
                                sommatoria += j;
                                trovato = 1;
                            }
                        }
                    }
                    else {
                        sommatoria += cfp.charAt(i) * 1;
                    }
                }
                else {
                    let tmp = 0;
                    if (isNaN(cfp.charAt(i))) {
                        let trovato = 0;

                        for (let j = 0; j < this.cf.lettere.length && trovato == 0; j++) {
                            if (this.cf.lettere[j] == cfp.charAt(i)) {
                                tmp = j;
                                trovato = 1;
                            }
                        }
                    }
                    else {
                        tmp = cfp.charAt(i) * 1;
                    }

                    sommatoria += this.cf.conversione[tmp];
                }
            }

            ritorno = (sommatoria % 26);

            ritorno = this.cf.lettere[ritorno];

            return ritorno;
        },

        calcolaCodiceFiscale() {
            let risultato = '';

            if (this.name == '') return "";

            let name = this.name.replace(/[^a-zA-Z]/gi, '');

            let consonanti_nome = this.controllaNome(name);
            if (consonanti_nome.length < 3) return "";

            if (this.surname == '') return "";

            let surname = this.surname.replace(/[^a-zA-Z]/gi, '');

            let consonanti_cognome = this.controllaCognome(surname);
            if (consonanti_cognome.length < 3) return "";

            if (this.date == '') return "";
            let dataN = this.controllaNascita(this.date, this.sex);

            let comune = this.idcity.code;

            risultato = consonanti_cognome + consonanti_nome + dataN + comune;

            let carattere = this.generaControllo(risultato);

            risultato += carattere;

            this.taxcode = risultato;

            this.salvaCodiceFiscale();
            this.closeModal();
        },

        salvaCodiceFiscale() {
            if (this.taxcode) {
                this.$emit("input", this.taxcode);
                //this.$bvModal.hide(this.modalid);
            }
        },
    },
};
</script>

<style>
.taxcodecentered {
    background: white;
    padding: 20px;
    width: 50%;
    min-width: 400px;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.taxcodetop {
    background: white;
    padding: 20px;
    width: 50%;
    min-width: 400px;

    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
}
</style>
